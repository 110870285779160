#more-like-this {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    display: flex;
    align-items: center;
    transition: opacity 1s;
    background-color: black;
    z-index: 1020;
}
#more-like-this > p {
    margin: auto;
}
#more-like-this.show {
    opacity: 1;
    transition: opacity 1s;
}

#series {
    z-index: 1;
    max-width: 600px;
    width: 100%;
    color: white;
    margin: auto;
    font-family: Tahoma, Verdana, Segoe, sans-serif;
    letter-spacing: .5px;
    padding-bottom: 1px;
    font-size: 20px;
}
#series a {
    color: inherit;
}
#series .title-text {
    font-weight: 700;
}
#series .normal-text {
    color: #919191;
    line-height: 1.4rem;
}
.episode-modal .modal-content {
    /* Hack to only see the content on the modal, without anything around it. */
    width: 0;
    height: 0;
}
#series #series-topbar {
    width: 100%;
}
#series #series-topbar .tab {
    text-transform: uppercase;
    display: inline-block;
    padding: 15px;
    font-size: 18px;
}
#series #series-topbar .tab.active, #series #series-topbar .tab:hover {
    border-top: solid #e5080d 5px;
    cursor: pointer;
}
#series #season {
    margin: 10px 0 12px;
}
#series #season .dropdown-toggle {
    padding: 10px 20px 10px 20px;
    border: none;
    border-radius: 5px;
}
#series #season .dropdown-toggle::after {
    margin-left: 1rem;
}
#series #season .dropdown-toggle, #series #season .dropdown-menu {
    background-color: #262626;
    color: white;
}
#series #season .dropdown-menu a:hover {
    background-color: #5c5c5c;
}
#series #episodes .episode {
    margin-bottom: 50px;
}
#series #episodes .episode .episode-video {
    height: 100px;
}
#series #episodes .episode .episode-video > iframe {
    height: 100%;
    width: 50vw;
    max-width: 200px;
    border-bottom: solid #e5080d 2px;
    border-radius: 5px;
    font-size: 2rem;
    display: flex;
    align-items: center;
}
#series #episodes .episode .episode-music {
    margin-top: 5px;
    color: #919191;
}
#series #episodes .episode .episode-title-box {
    padding-left: 0;
}
#series #episodes .episode .episode-download  {
    display: flex;
    align-items: center;
}
#series #episodes .episode .episode-download svg {
    border-bottom: solid white 3px;
    height: 30px;
    margin: 0 auto;
    display: block;
}
#series #episodes .episode .episode-description {
    margin-top: 15px;
    width: 100%;
    max-width: 500px;
}
