.cover-page {
    background-color: black;
}
.cover-page img {
    width: 100%;
    height: 100%;
}
.text-page {
    background-color: black;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    padding-top: 24%;
}
.image-page {
    background-color: black;
}
.image-page img {
    width: 100%;
    height: 100%;
}
#siren-book {
    margin: 0;
    padding: 0;
    cursor: move;
}
#siren-book.flipping {
    overflow: hidden;
}
#siren-book {
    position: relative;
}
button.siren-fullscreen.desktop {
    opacity: 0;
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 1;
    transition: opacity .1s ease-out;
    margin: 15px 0;
}
#siren-book:hover .siren-fullscreen {
    opacity: .8;
    transition: opacity .1s ease-in;
}
button.siren-fullscreen.mobile {
    opacity: .8;
}
