.responsive-youtube-embed {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}
.responsive-youtube-embed > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
