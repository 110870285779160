#music-links {
    margin-bottom: 8px;
}
#music-links a, #music-links button {
    padding: 2px;
    margin: 2px;
    color: black;
    border: none;
    background: transparent;
}
#music-links button {
    color: #449fd3;
}
