#social-media {
	display: flex;
	height: 100%;
	width: 100%;
}
#social-media > div {
	width: 100%;
}

#twitter {
	height: 100%;
	display: flex;
	width: 100%;
	flex-direction: column;
}
#twitter > div {
	height: 100%;
}

.date-added {
	font-size: 0.7rem;
	font-style: oblique;
	text-align: right;
}

.carousel-inner {
	width: 85%;
	margin-left: auto;
	margin-right: auto;
}

.prev-wrap, .next-wrap {
	background-color: rgba(0,0,0,.5);
	padding: 10px;
	border-radius: 50%;
}

.prev-wrap:hover, .next-wrap:hover {
	background-color: rgba(0,0,0,.7);
}

.carousel-control-prev, .carousel-control-next {
	width: 5%;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
	display: block;
}

@keyframes cross-fade {
    0%   { opacity: 0; }
	100%   { opacity: 1; }
}

.cross-fade {
    animation-name: cross-fade;
    animation-duration: 0.6s;
    transform-origin: 50% 50%;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}
