html, body, #root {
    height: 100%;
}
body {
    box-sizing: border-box;
}
#root {
    display: flex;
    flex-direction: column;
}
main {
    flex-grow: 1;
    padding-bottom: 10px;
}
a {
    color: inherit;
}
.dark-mode {
    transition: background-color .5s;
    background-color: black;
    color: white;
}
.light-mode {
    transition: background-color .5s;
}
.mailchimp-form input {
    padding-left: 5px;
    border: solid 1px lightgrey;
    border-right: none;
}
.mailchimp-form button {
    color: white;
    background: #318cc1;
    border: none;
    border-radius: 5px;
    margin-left: -5px;
    padding-top: 2px;
    padding-bottom: 2px;
}
