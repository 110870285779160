#piano {
	margin-top: -30px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 10px;
	overflow: hidden;
	width: fit-content;
}

#piano a {
    text-decoration: none;
}

#piano-links {
	display: initial;
}

.piano {
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
}

.piano-key {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	float: left;
}

.white-key {
	height: 10em;
	width: 4em;
	z-index: 1;
	border-left: 1px solid #bbb;
	border-bottom: 1px solid #bbb;
	border-radius: 0 0 5px 5px;
	box-shadow: -1px 0 0 rgba(255,255,255,0.8) inset, 0 0 5px #ccc inset, 0 0 3px rgba(0,0,0,0.2);
	background: white;
}

.white-key:active {
	border-top: 1px solid #777;
	border-left: 1px solid #999;
	border-bottom: 1px solid #999;
	box-shadow: 2px 0 3px rgba(0,0,0,0.1) inset, -5px 5px 20px rgba(0,0,0,0.2) inset,0 0 3px rgba(0,0,0,0.2);
	background: linear-gradient(to bottom, #fff 0%,#e9e9e9 100%);
}

.black-key {
	height: 6em;
	width: 2em;
	margin: 0 0 0 -1em;
	z-index: 2;
	border: 1px solid #000;
	border-radius: 0 0 3px 3px;
	box-shadow: -1px -1px 2px rgba(255,255,255,0.2) inset, 0 -5px 2px 3px rgba(0,0,0,0.6) inset, 0 2px 4px rgba(0,0,0,0.5);
	background: linear-gradient(45deg, #222 0%,#555 100%);
}

.black-key:active {
	box-shadow: -1px -1px 2px rgba(255,255,255,0.2) inset, 0 -2px 2px 3px rgba(0,0,0,0.6) inset, 0 1px 2px rgba(0,0,0,0.5);
	background: linear-gradient(to right, #444 0%,#222 100%);
}

.piano-key span {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
	white-space: nowrap;
	display: flex;
	margin-top: 1.5em;
	font-size: 1.2em;
	font-weight: normal;
	text-shadow: 0px 0px 0.5px #333;
	text-transform: uppercase;
	text-align: center;
	color: black;
}
.black-key span {
	color: #f8f9fa;
	margin-top: .5em;
	text-shadow: 0px 0px 1px #FFF;
}

.piano-key i {
	color: black;
}
.piano-key i::before {
	font-size: 1.5em;
}

.piano-link {
	background-color: #318cc1;
}

.piano-link:hover {
	text-decoration: none;
	color: black;
	background-color: #5aaedf;
}

.a,.g,.f,.d,.c {
	margin:0 0 0 -1em;
}

.c .key-text ,.f .key-text {
	padding-right: 1em;
}

.e,.b {
	margin: 0 1em 0 -1em;
}
.e .key-text,.b .key-text {
	padding-left: 1em;
}

.d .key-text, .g .key-text, .a .key-text {
	padding-left: .5em;
	padding-right: .5em;
}

.key-icon {
	margin-top: 50px;

	border: 1px solid #aa6807;
	border-radius: 50%;
	width: 2.6em;
	margin-left: auto;
	margin-right: auto;
	background-color: #ffffffbf;

	color: black;
}
.key-icon i::before {
	margin-left: .3em;
}
