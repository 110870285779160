body {
	font-family: 'Abel', sans-serif !important;
}

h2 {
	margin: 10px;
}

main {
	padding-top: 2rem;
}

.no-padding-top {
	padding-top: 0;
}

.blue-flyer {
	background-color: #7ad3d3;
}

.text-blue-flyer {
	color: #7ad3d3;
}

.text-red {
	color: red;
}
